/* ============
 * Company actions
 * ============
 *
 * The actions available for the company module.
 */

import api from '@/api/Company';
import * as types from './types';

export default {
  /**
   * Action which will create a new company.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  create({ dispatch }, payload) {
    return api.create(payload).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: `Vous avez créé la société ${payload.name} !`,
          color: 'success',
          route: `/company/${data.data.id}`,
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will update a new company.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {String} company The company id.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  update({ dispatch }, { company, payload }) {
    return api.update(company, payload).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: 'Vous avez modifié cette société !',
          color: 'success',
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will get a company.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Boolean} includeUsers true to include users.
   * @param {Boolean} includeAccounts true to include accounts.
   *
   * @returns {Promise} The result in a promise.
   */
  get({ commit }, { company, includeUsers = true, includeAccounts = true }) {
    return api.get(company, includeUsers, includeAccounts).then((data) => {
      commit(types.SET_COMPANY, data.data);
      return data;
    });
  },

  /**
   * Action which will delete a company.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {String} company The company id.
   *
   * @returns {Promise} The result in a promise.
   */
  delete({ dispatch }, company) {
    return api.delete(company).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: 'Vous avez supprimé la société et ses utilisateurs rattachés avec succès !',
          color: 'success',
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will get all companies.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} filter The filter to apply.
   *
   * @returns {Promise} The result in a promise.
   */
  getAll({ commit }, filter) {
    return api.getAll(filter).then((data) => {
      commit(types.SET_COMPANIES, data.data);
      return data;
    });
  },

  /**
   * Action which will get accounts with pagination for a company.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} filter The filter to apply.
   *
   * @returns {Promise} The result in a promise.
   */
  getAccounts({ commit }, { company, filter }) {
    return api.getAccounts(company, filter).then((data) => {
      commit(types.SET_ACCOUNTS, data.data);
      return data;
    });
  },
  /**
   * Action which will get all accounts for a company.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {String} companyId The company id.
   *
   * @returns {Promise} The result in a promise.
   */
  async getAllAccounts({ commit }, companyId) {
    let paginatedAccounts = [];
    let allAccounts = [];
    let currentPage = 0;
    const promises = [];

    paginatedAccounts = await api.getAccounts(
      companyId,
      {
        filters: null,
        limit: 100,
        offset: currentPage,
        sort: {
          direction: 'ascending',
          field: 'name',
        },
      },
      false,
    );
    allAccounts = paginatedAccounts.data.accounts;
    if (paginatedAccounts.data.pagination.totalPages > 1) {
      while (currentPage < paginatedAccounts.data.pagination.totalPages - 1) {
        currentPage += 1;
        promises.push(api.getAccounts(
          companyId,
          {
            filters: null,
            limit: 100,
            offset: currentPage,
            sort: {
              direction: 'ascending',
              field: 'name',
            },
          },
          false,
        ));
      }
    }
    const all = await Promise.all(promises);
    const toAdd = all.map((a) => a.data.accounts).flat();
    allAccounts = allAccounts.concat(toAdd);

    commit(types.SET_COMPANY_ALL_ACCOUNTS, allAccounts);
    return allAccounts;
  },

  /**
   * Action which will update a new company.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {String} company The company id.
   * @param {Object} user The user.
   *
   * @returns {Promise} The result in a promise.
   */
  addUser({ dispatch }, { company, user }) {
    return api.addUser(company, user).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: `Vous avez ajouté ${user.email} à la société !`,
          color: 'success',
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will update a new company.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {String} company The company id.
   * @param {Object} user The user.
   *
   * @returns {Promise} The result in a promise.
   */
  updateUser({ dispatch }, { company, user, payload }) {
    return api.updateUser(company, user.id, payload).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: `Vous avez modifié ${user.firstName} ${user.lastName} avec succès !`,
          color: 'success',
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will update a new company.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {String} company The company id.
   * @param {Object} user The user id.
   *
   * @returns {Promise} The result in a promise.
   */
  deleteUser({ dispatch }, { company, user }) {
    return api.deleteUser(company, user).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: "Vous avez supprimé l'utilisateur de la société !",
          color: 'success',
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will update a new company.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {String} company The company id.
   * @param {Object} user The user id.
   *
   * @returns {Promise} The result in a promise.
   */
  inviteUser({ dispatch }, { company, user }) {
    return api.inviteUser(company, user).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: 'Vous avez invité un utilisateur dans la société !',
          color: 'success',
        },
        { root: true },
      );
      return data;
    });
  },
};
